/* Font Definitions */
.outfit-text {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.crimson-text-subhead {
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-style: normal;
}
.crimson-text-head {
  font-family: "Crimson Text", serif;
  font-weight: 600;
  font-style: normal;
}
.pacifico-regular {
  font-family: "Pacifico", serif;
  font-weight: 400;
  font-style: normal;
}



/* Sizing */
img.smallcover {
	max-width:200px;
	float:left;
	padding:20px;
}
img.smallercover {
	max-width:150px;
	float:left;
	padding:10px;
}



/* HTML */
body {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
h1 {
	margin:0;
	padding:0;
	font-size: 2.5em;
}
h2 {
	font-size: 1.4em;
	margin:0;
	padding:0;
}
h3 {
	margin:0;
	padding:0;
}

/* BASICS */
.container-fluid {
	width: 100%;
}
.img-fluid {
	width: 100%;
}


/* Adjustments */
.fineprint {
	font-size: .5em;
}
.intro {
	margin:4em;
}
.headshot img {
	float:left;
	margin-right:20px;
	border-radius:20px;
}


/* NAVBAR */
#navbar {
  position: sticky;
  top: 0;
  overflow: hidden;
  background-color: #333;	
}
.navbar-brand {
	text-decoration: none;
	color: white;
}
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
.topnav .icon {
  display: none;
}
@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}


/* BUTTONS */
.btn {
	background-color: #0066ff;
	border-radius: 5px;
	padding: 10px;
	color: white;
	text-decoration: none;
}
.altbtn {
	background-color: #000000;
	border-radius: 5px;
	padding: 10px;
	color: white;
	text-decoration: none;
	display:inline-block;
	box-sizing: border-box;
	margin:4px;
}


/* BOOKS */
.bookitem {
	margin: 20px 0px 0px 30px;
}
.formats {
	font-size:.8em;
}
.praise {
	margin:0px 40px 60px 40px;
}
.praise h1 {
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 2em;
}
.praise blockquote {
	margin-left:40px;
	margin-right:40px;
}

/* SERIES */
.series {
	margin:10px;
	padding:10px;
	border: 1px solid black;
	border-radius: 10px;
}
.series-header {
	margin-bottom:10px;
}
.series-body {}